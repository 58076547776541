import React from 'react';
import Playbar from '../components/molecules/Playbar';
import { createGlobalStyle } from 'styled-components';
import { Row } from '../components/Layout/Flex';
import { colours } from '../assets/theme';

const GlobalStyle = createGlobalStyle`
body {
  background:none transparent;
  }
`;

const NowPlayingPage = () => (
  <>
    <GlobalStyle />
    <Playbar standalone />
  </>
);

export default NowPlayingPage;
